/* Contact.css */

.contact-form {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Adjust column sizes as needed */
  column-gap: 1rem; /* Space between columns */
  row-gap: 0.5rem; /* Reduced space between rows */
  margin-bottom: 2px;
}

/* Labels and inputs/selects/textareas will each take up one row, spanning across both columns */
.contact-form label,
.contact-form input,
.contact-form select,
.contact-form textarea,
.contact-form button {
  grid-column: 1 / -1; /* Span from the first line to the last line of the grid */
}

/* Add margins to form elements */
.contact-form input,
.contact-form select,
.contact-form textarea,
.contact-form button {
  margin-top: 0.25rem; /* Small top margin to separate from the field above */
}

/* Style adjustments for individual form elements if needed */
.contact-form label {
  margin-bottom: 0.25rem; /* Smaller bottom margin to bring label closer to its field */
  display: block;
  justify-self: start;
  /* Smaller bottom margin to bring label closer to its field */
}

.contact-container {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
  background-color: #F0EBE3; /* Use your lightest color for the background */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: add some shadow for depth */
}

.contact-header h1 {
  color: #576F72;
  margin-bottom: 1rem;
}

.contact-header p {
  color: #576F72;
  margin-bottom: 2rem;
}


.contact-form button {
  padding: 0.5rem 1rem;
  background-color: #7D9D9C; /* Use your muted teal for button background */
  color: #F0EBE3; /* Use your lightest color for the text */
  border: none;
  border-radius: 4px; /* Optional: rounded corners */
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #576F72; /* A shade darker for hover state */
}

.contact-info {
  text-align: center;
  padding: 1rem;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .contact-form {
    grid-template-columns: 1fr; /* On smaller screens, use one column */
  }

  .contact-form label,
  .contact-form input,
  .contact-form select,
  .contact-form textarea,
  .contact-form button {
    grid-column: auto; /* Allow items to automatically span the full width */
  }
}
