.home-container {
  display: flex;
  flex-direction: column;
}

.hero-section {
  text-align: center;
  padding: 2rem;
  background-color: #F0EBE3;
}

.portfolio-section {
  margin: 2rem 0;
}

.portfolio-section h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.image-gallery, .editing-gallery {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image-gallery div, .editing-gallery div {
  /* Example dimensions, adjust as needed */
  width: 30%;
  margin: 1rem;
  position: relative;
}

.image-gallery img, .editing-gallery img {
  width: 100%;
  height: auto;
  border: 3px solid #576F72; /* Adds a border around the image */
}

.editing-gallery .before-after {
  display: flex;
}

.cta-button {
  padding: 1rem 2rem;
  background-color: #576F72;
  color: white;
  border: none;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #7D9D9C;
}

/* Add responsiveness */
@media (max-width: 768px) {
  .image-gallery div, .editing-gallery div {
    width: 45%;
  }

  .hero-section {
    padding: 1rem;
  }

  .cta-button {
    padding: 0.5rem 1rem;
  }
}

.slider-image {
  width: 40%; /* or any specific width */
  height: auto; /* maintain aspect ratio */
  display: block; /* to remove bottom space/margin */
  margin: 0 auto; /* center the image if smaller than the container */
}

/* Centering the section title */
.editing-examples-section h2 {
  text-align: center; /* Center the section title */
}

/* Adjusting the container and image sizes */
.editing-gallery .before-after-container {
  display: flex;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust based on your spacing needs */
}

/* Adjusting the container for editing examples */
.editing-examples-section {
  max-width: 90%; /* Increase the maximum width of the container */
  margin: 0 auto; /* Center the container on the page */
  padding: 20px; /* Add some padding inside the container */
}

.editing-gallery {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap in the container */
  justify-content: space-around; /* Distributes space around the items */
  gap: 20px; /* Adds space between the items */
}

.editing-gallery img {
  width: 100%; /* Use the full width of the parent container */
  max-width: 1080px; /* Set a max-width to control the size */
  height: auto; /* Maintain aspect ratio */
}

