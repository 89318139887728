/* About.css */

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adjust the space between the image and text */
  padding: 20px;
}

.about-image img {
  width: 50vw; /* The image will take up 50% of the viewport width */
  max-width: 750px; /* Adjust the max width as needed */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 10px; /* Optional: for rounded corners */
}

.about-text {
  flex: 1; /* Allows the text container to fill the remaining space */
  max-width: 600px; /* Adjust the max width as needed */
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-image img {
    width: 80vw; /* Adjust if you want a different width on smaller screens */
    max-width: 100%; /* Allows the image to be full width on smaller screens */
  }

  .about-text {
    max-width: 100%;
  }
}
