/* global.css */

html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: auto;
}

/* Reset some basic elements */
p, ol, ul, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, blockquote {
  margin: 0;
  padding: 0;
}

/* Set default font and background for the body */
body {
  font-family: 'Arial', sans-serif; /* This is a placeholder, update with your preferred font */
  background-color: #F0EBE3; /* Lightest color for the background */
  color: #576F72; /* Darkest color for the text */
  line-height: 1.6;
  font-size: 16px; /* Base font size */
}

/* Styles for headers; adjust sizes as needed */
h1, h2, h3, h4, h5, h6 {
  color: #576F72; /* Using the dark slate color for header text */
  margin-bottom: 1rem;
}

/* Link styles */
a {
  color: #7D9D9C; /* Muted teal for links */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Button and input styles */
button, input, select, textarea {
  font: inherit;
  color: inherit;
  background-color: #E4DCCF; /* Neutral shade for input backgrounds */
  border: 1px solid #7D9D9C; /* Muted teal for borders */
  padding: 0.5rem;
  margin-top: 0.5rem;
}

button:hover, input[type="submit"]:hover, input[type="button"]:hover {
  background-color: #7D9D9C; /* Darker background on hover for buttons */
  color: #F0EBE3; /* Lightest color for text */
  cursor: pointer;
}

/* Container for wrapping the content */
.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

/* Navigation styles */
nav {
  background: #7D9D9C; /* Muted teal for navigation background */
  color: #F0EBE3;
  padding: 1rem;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

nav ul li {
  padding: 0.5rem;
}

/* Utility classes for padding and margin */
.padding {
  padding: 1rem;
}

.margin {
  margin: 1rem;
}

#root, .site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
  padding: 20px; /* Adjust the padding as needed */
}


/* You can continue adding more styles as per your requirement */
