/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #576F72; /* Muted teal from your color palette 576F72 */
  padding: 1rem;
}

.navbar-brand .nav-logo {
  color: #F0EBE3; /* Lightest color for the brand text */
  text-decoration: none;
  font-size: 1.5rem; /* Larger font size for the brand/logo */
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-item {
  margin: 0 1rem;
  color: #F0EBE3; /* Lightest color for link text */
  text-decoration: none;
}

.nav-item:hover {
  color: #E4DCCF; /* Lighter shade for hover state */
}
