/* Footer.css */

html, body {
  height: 100%; /* make sure the body takes up full viewport height */
  margin: 0; /* reset any default margin */
}

#root {
  display: flex;
  flex-direction: column; /* arrange children (content and footer) in a column */
  min-height: 100%; /* minimum height to fill the viewport */
}

.main-content {
  flex: 1; /* allows the content to grow and take up available space, pushing the footer down */
}

.footer {
  background-color: #576F72; /* Darker color for the footer background */
  color: #F0EBE3; /* Light color for the text */
  text-align: center;
  padding: 1rem;
  width: 100%;
}

.footer p {
  margin: 0; /* Removes the default margin from the paragraph */
}
